const GQL = (query) => {
    const myHeader = new Headers();
    myHeader.append("Accept", "application/json");
    myHeader.append("Content-Type", "application/json");
  
    const myInit = {
      method: "POST",
      headers: myHeader,
      body: JSON.stringify({
        query: query
      })
    }
    const myRequest = new Request(`${process.env.REACT_APP_API}/graphql/`, myInit); return fetch(myRequest).then(res => res.json());
  }
  export default GQL;