
import React, { useState, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import GetAppIcon from '@material-ui/icons/GetApp';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CallIcon from '@material-ui/icons/Call';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import ModalAksi from './Penawaran.Modal'
import Skeleton from 'react-loading-skeleton';
import mControl from '../Controler/penawaran'

let tinggi = 400
const Penawaran = (props) => {
    const perusahaanID = props.match.params.perusahaanID
    const notrans = props.match.params.notrans
    tinggi = window.innerHeight - 200
    const [isReaload, setIsReaload] = useState(true)
    const [isLogin, setIsLogin] = useState(true)
    const [password, setPassword] = useState(null)
    const [showSalah, setShowSalah] = useState(false)
    const [showSukses, setShowSukses] = useState(false)
    const [aksiID, setAksiID] = useState()
    const [openAksi, setOpenAksi] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        fetch("https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91")
            .then(res => res.json())
            .then(resp => {
                mControl.simpanView(perusahaanID, notrans, resp.IPv4).then(resp => {
                }).catch(err => {
                    console.error(err);
                })
            })
        return () => {
        }
    }, [isReaload, perusahaanID, notrans])

    useEffect(() => {
        mControl.loadData(perusahaanID, notrans).then(resp => {
            setData(resp)
        }).catch(err => {
            console.error(err);
        }).finally(() => setIsReaload(false))
        return () => {

        }
    }, [isReaload, perusahaanID, notrans])

    const cekPassword = () => {
        if (password === data.password) {
            setIsLogin(true)
        } else {
            setShowSalah(true)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSalah(false)
        }, 3000);
        return () => clearTimeout(timer);
    }, [showSalah]);

    const aksi = id => {
        setAksiID(id * 1)
        setOpenAksi(true)
    }


    // eslint-disable-next-line
    const Iframe = () => <iframe src={`https://docs.google.com/viewerng/viewer?url=${data.linkPDF}&embedded=true`} frameBorder="1" height={tinggi} width="100%">
    </iframe>
    // eslint-disable-next-line
    const Download = () => <a href={data.linkPDF} target="_blank" className="download-pdf"><GetAppIcon />Download Document</a>
    return (
        <React.Fragment>
            <CssBaseline />
            {isLogin ? (
                <React.Fragment>
                    {isReaload ? (
                        <React.Fragment>
                            <Skeleton />
                            <Skeleton height={tinggi} />
                            <Skeleton />
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <Container style={{ marginTop: 70 }}>
                                    <Box my={2}>
                                        {showSukses && <Alert style={{ marginBottom: 10 }} severity="success">Terima kasih, kami akan segera menghubungi anda</Alert>}
                                        <h6 className="text-center">Hi <b>{data.namaperusahaan}</b> !!</h6>
                                        {data.linkPDF && (
                                            <Iframe />
                                        )}
                                    </Box>
                                    <Box my={2}>
                                        <div className="App">
                                            <Download />
                                        </div>
                                    </Box>
                                </Container>
                                <div className="fixFooter">
                                    <ButtonGroup fullWidth variant="contained" color="primary">
                                        <Button color="secondary" onClick={aksi.bind(this, 0)}><CallIcon />Negotiation</Button>
                                        <Button><CheckBoxIcon onClick={aksi.bind(this, 1)} /> Deal </Button>
                                    </ButtonGroup>
                                </div>
                            </React.Fragment>
                        )}
                    {openAksi && (
                        <ModalAksi open={openAksi} setOpen={setOpenAksi} aksiID={aksiID} perusahaanID={perusahaanID} notrans={notrans} setShowSukses={setShowSukses} />
                    )}
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        <Container style={{ marginTop: 70 }}>
                            {isReaload ? (
                                <React.Fragment>
                                    <Skeleton />
                                    <Skeleton height={tinggi} />
                                    <Skeleton />
                                </React.Fragment>
                            ) : (
                                    <Box my={2}>
                                        <h6 className="text-center">Hi, <b>{data.namaperusahaan}</b> !!</h6>
                                        <p className="text-center">Masukan Password untuk buka dokument ini.</p>
                                        {showSalah && (<Alert style={{ marginBottom: 10 }} severity="error" onClose={() => { setShowSalah(false) }}>Password yang anda masukan salah</Alert>)}
                                        <TextField type="password" id="outlined-basic" label="Password" variant="outlined" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                cekPassword()
                                            }
                                        }} />
                                        <Button style={{ marginTop: 10 }} variant="contained" color="primary" fullWidth onClick={cekPassword}>Oke</Button>
                                    </Box>
                                )}
                        </Container>
                    </React.Fragment>
                )}
        </React.Fragment>
    )
}

export default Penawaran;