/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

const Home = () => {
    return (
        <Container>
            <Row>
                <Col style={{ marginTop: 100 }}>
                    <h4 className="text-center"> Welcome to <a href="https://homansystem.com/">Homan System </a></h4>
                </Col>
            </Row>
        </Container>
    )
}

export default Home;