import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Index from './Components/index'
import Penawaran from './Components/Penawaran'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography'

function App() {
  return (
    <Router>
      <AppBar>
        <Toolbar>
          <Typography variant="h6">Homan System</Typography>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/:perusahaanID/:notrans" component={Penawaran} exact />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
