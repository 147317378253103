import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import mControl from '../Controler/penawaran'


const Modal = (props) => {
    const [remark, setRemark] = useState('')
    const [rate, setRate] = useState(0)
    const [isAlert, setIsAlert] = useState(false)
    const [alertText, setAlertText] = useState("")

    const handleClose = () => {
        props.setOpen(false);
    };

    const simpan = () => {
        if (remark.length <= 0 && rate <= 0) {
            setAlertText("Wajib isi remark dan rate !!!")
            setIsAlert(true)
        } else if (remark.length <= 0) {
            setAlertText("Wajib isi remark !!!")
            setIsAlert(true)
        } else if (rate <= 0) {
            setAlertText("Wajib isi rate !!!")
            setIsAlert(true)
        } else {
            const action = props.aksiID === 1 ? "deal" : "negotiation"
            mControl.simpanFeedback(props.perusahaanID, props.notrans, action, remark, rate).then(resp => {
                handleClose()
                props.setShowSukses(true)
            }).catch(err => {
                setIsAlert(true)
                if (err.length > 0) {
                    setAlertText(`Gagal Simpan : ${err[0].message}`)
                } else {
                    setAlertText(`Gagal Simpan : ${err.message}`)
                }
            })
        }
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsAlert(false)
        }, 3000);
        return () => clearTimeout(timer);
    }, [isAlert]);

    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.aksiID === 1 ? "Deal" : "Negotiation"}</DialogTitle>
            {isAlert && (
                <DialogContentText>
                    <Alert severity="error" onClose={() => { setIsAlert(false) }}>{alertText}</Alert>
                </DialogContentText>
            )}
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="Remark"
                    label="Remark"
                    fullWidth
                    rows={2}
                    multiline={true}
                    required
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                />

                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Rate</Typography>
                    <Rating
                        name="rate"
                        value={rate}
                        onChange={(event, newValue) => {
                            setRate(newValue);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button onClick={simpan} color="primary">
                    Oke
          </Button>
            </DialogActions>
        </Dialog>
    )
}
export default Modal;
