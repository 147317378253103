import GQL from '../GQL';
const Penawaran = {
    loadData: (perusahaanID, notrans) => {
        return new Promise((resolve, reject) => {
            const query = `
            query{
                lihatPenawaran(perusahaanID:${perusahaanID},notrans:"${notrans}"){
                    urut
                    notrans
                    perusahaanID
                    password
                    linkPDF
                    namaperusahaan
                } 
            }
            `
            GQL(query).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp.data) return (resolve(resp.data.lihatPenawaran));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    simpanView: (perusahaanID, notrans, ipaddrress) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                simpanViewPenawaran(perusahaanID:${perusahaanID},notrans:"${notrans}", ipaddrress:"${ipaddrress}")
            }
            `
            GQL(query).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp.data) return (resolve(resp.data.simpanViewPenawaran));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    simpanFeedback: (perusahaanID, notrans, action, remark, rate) => {
        return new Promise((resolve, reject) => {
            const query = `
            mutation{
                simpanFeedbackPenawaran(perusahaanID:${perusahaanID},notrans:"${notrans}", action:"${action}",remark:"${remark}", rate: ${rate})
            }
            `
            GQL(query).then(resp => {
                if (resp.errors) return (reject(resp.errors));
                if (resp.data) return (resolve(resp.data.simpanFeedbackPenawaran));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
}

export default Penawaran;